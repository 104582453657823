import {
  Button,
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CalculatorTitle from "./CalculatorTitle";

function COPTarget(props) {
  const US_AVERAGE_ELECTRICITY_COST = 13.72;
  const US_AVERAGE_GAS_COST = 12.04;
  const US_AVERAGE_GAS_UNIT = "mcf";

  const [state, setState] = useState({
    electricityCost: US_AVERAGE_ELECTRICITY_COST,
    gasCost: US_AVERAGE_GAS_COST,
    afue: 98,
    gasUnit: US_AVERAGE_GAS_UNIT,
  });

  const [showAdvancedState, setShowAdvancedState] = useState(false);

  const MCF_TO_THERM = 10.37;
  const THERM_TO_KWH = 29.3001111;

  // get gas cost in kWh
  let gasCostTherms = state.gasCost;
  if (state.gasUnit === "mcf") {
    gasCostTherms = gasCostTherms / MCF_TO_THERM;
  }
  const gasCostkWh = gasCostTherms / THERM_TO_KWH;

  const gasCostkWhFurnaceEfficiency = gasCostkWh / (state.afue / 100);

  // convert electricity to dollars
  const cop_threshold =
    state.electricityCost / 100 / gasCostkWhFurnaceEfficiency;

  const handleChange = (e) => {
    const { name, value } = e.target;
    window.gtag("event", "cop_target.calculation", {
      ...state,
      valueChanged: name,
    });
    console.log(name, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Container maxWidth="md" sx={{ paddingBottom: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CalculatorTitle
            title="Target COP Calculator"
            description={props.about}
          />
        </Grid>

        <Grid item xs={12}>
          <h2 style={{ fontWeight: "normal" }}>
            How much do you pay for electricity?
          </h2>
          <p>
            The average price of electricty in the US is{" "}
            {US_AVERAGE_ELECTRICITY_COST} ¢/kwh (April 2022). Look up the
            average for your state{" "}
            <a href="https://www.saveonenergy.com/electricity-rates/">here</a>.
          </p>
          <TextField
            type="number"
            name="electricityCost"
            label="¢/kwh"
            sx={{ width: "100px" }}
            value={state.electricityCost}
            variant="outlined"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 0.01 }}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <h2 style={{ fontWeight: "normal" }}>
            How much do you pay for natural gas?
          </h2>
          <p>
            The average price of electricty in the US is ${US_AVERAGE_GAS_COST}{" "}
            per 1000 Cubic Feet (Jan 2022). Look up the average for your state{" "}
            <a href="https://www.chooseenergy.com/data-center/natural-gas-rates-by-state/">
              here
            </a>
            .
          </p>

          <TextField
            type="number"
            sx={{ mr: 2, width: "100px" }}
            label="Price for Gas"
            value={state.gasCost}
            variant="outlined"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              step: 0.01,
            }}
            onChange={handleChange}
            name="gasCost"
          />

          <FormControl>
            <InputLabel id="gas-unit-label">Unit</InputLabel>

            <Select
              labelId="gas-unit-label"
              id="gas-unit"
              value={state.gasUnit}
              label="Unit"
              name="gasUnit"
              onChange={handleChange}
            >
              <MenuItem value={"therm"}>$/Therm</MenuItem>
              <MenuItem value={"mcf"}>$/Thousand Cubic Feet</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            sx={{ mt: 1 }}
            variant="text"
            onClick={() => {
              window.gtag("event", "cop_target.toggle_advanced", {
                new_state: state.showAdvancedState ? "closed" : "opened",
              });
              setShowAdvancedState((prev) => !prev);
            }}
          >
            {showAdvancedState ? "Hide" : "Show"} Advanced Options
          </Button>

          <div
            style={{
              height: showAdvancedState ? "100%" : "0%",
              opacity: showAdvancedState ? "1" : "0",
              overflow: "hidden",
              display: showAdvancedState ? "initial" : "none",
              transition: "all 0.5s ease-in-out",
            }}
          >
            <h2 style={{ fontWeight: "normal" }}>
              How efficient is your furnace?
            </h2>
            <p>
              Furance efficiency is measured by Annual Fuel Utilization
              Efficiency, or "AFUE". Modern high efficiency systems can have a
              AFUE of up to 98.5%. Older systems can be as low as 56%.
            </p>

            <TextField
              type="number"
              label="AFUE"
              value={state.afue}
              variant="outlined"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                step: 1,
              }}
              onChange={handleChange}
              name="afue"
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "normal", marginBottom: 2 }}
          >
            Results
          </Typography>
          <Box
            sx={{
              backgroundColor: "rgb(252,198,81)",
              padding: 3,
              borderRadius: 2,
              // color: "primary.contrastText",
            }}
          >
            <Box style={{ fontSize: "1.2em" }}>
              A heat pump with a COP above{" "}
              <span
                style={{
                  fontSize: "1.5em",
                  borderBottom: "2px solid",
                  padding: "0 3px",
                }}
              >
                {cop_threshold.toFixed(2)}
              </span>{" "}
              will be cheaper to operate than a furnace.
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default COPTarget;
