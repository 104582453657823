import { Typography } from "@mui/material";

function CalculatorTitle(props) {
  return (
    <div>
      <h1 style={{ fontWeight: "normal" }}> {props.title} </h1>
      <Typography component="p" variant="">
        {props.description}
      </Typography>
    </div>
  );
}

export default CalculatorTitle;
