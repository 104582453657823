import { Container, Grid, Typography, Divider } from "@mui/material";
function About() {
  return (
    <Container maxWidth="sm" sx={{ mb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 style={{ fontWeight: "normal" }}>About </h1>
          <Typography component="p"></Typography>
          <p>
            It didn't surprise me that Americans burn a lot of fossil fuels to
            heat their homes<sup>1</sup>.
          </p>
          <p>
            What did surprise me is there's an existing technology available
            called a heat pump that can run on renewable energy and is much more
            energy efficient.
          </p>
          <p>
            I suspect a big barrier to heat pump adoption is awareness and tools
            to understand the feasibility of switching.
          </p>
          <p>
            I built this site to show that <b>Heat Pumps Work</b>.
          </p>
          <h2 style={{ fontWeight: "normal" }}>Get in touch</h2>
          If you have feedback or would like to work with me to make this site
          better, please get in touch.
          <p>
            Email: <a href="mailto:kmax12@gmail.com">kmax12@gmail.com</a>
          </p>
          <p>
            Twitter: <a href="https://twitter.com/maxk">@maxk</a>
          </p>
          <p>
            Linkedin:{" "}
            <a href="https://linkedin.com/in/jmaxkanter">Max Kanter</a>
          </p>
          <Divider color="#b9b2a9" sx={{ mb: 3 }} />
          <sup>1</sup>
          About 43% of all energy consumed in homes across the United States
          goes into heating. In colder regions like the Northeast and Midwest,
          that number is closer to 55%. For 58% of households, their main source
          of heat is fossil fuelA majority of which is fossil fuels.{" "}
          <a
            href="https://www.eia.gov/consumption/residential/data/2015/c&e/pdf/ce3.1.pdf"
            _target="blank"
          >
            Source
          </a>
        </Grid>
      </Grid>
    </Container>
  );
}

export default About;
