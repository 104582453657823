import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import {
  bindHover,
  bindPopover,
  usePopupState,
} from "material-ui-popup-state/hooks";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import heatpump from "./heat-pump.svg";
import { useState } from "react";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#b6602f"),
  backgroundColor: "#b6602f",
  "&:hover": {
    backgroundColor: "#8f461d",
  },
}));

const sectionBackgroundColor = "#f9f6f1";

function Home(props) {
  return (
    <div>
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: sectionBackgroundColor,
          paddingTop: 10,
          paddingBottom: 8,
        }}
      >
        <Container maxWidth="md" sx={{ color: "#1b1e2d" }}>
          <Box>
            <img
              src={heatpump}
              alt="Heat Pump Logo"
              style={{
                width: "75px",
                marginBottom: "-30px",
                marginLeft: "-7.5px",
              }}
            />
          </Box>
          <h1 style={{ fontWeight: "normal" }}>
            Ready to Jump to a{" "}
            <span className="underline--magical-home">Heat Pump</span>?
          </h1>
          <Box sx={{ fontSize: "1.2rem" }} mt={2}>
            Reduce your energy bill and carbon emissions
          </Box>

          <p>{/* <b>It's Time to Pump!</b> */}</p>
          {/* <p>by delivering </p> */}
        </Container>
      </Container>
      {/* <Container
        maxWidth={false}
        sx={{ backgroundColor: "#523A34", color: "#fff", paddingY: 6 }}
      > */}
      {/* #523A34 */}
      {/* <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2 style={{ fontWeight: "normal", textAlign: "center" }}>
                {" "}
                Why Heat Pumps?
              </h2>
              <p>
                Heat pumps can deliver up 4 times more heat energy than the
                electricity they consume.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Container> */}
      <Container maxWidth="md" sx={{ paddingY: 6 }}>
        <h2 style={{ fontWeight: "normal", textAlign: "center" }}>
          Heat Pump Calculators
        </h2>
        <p>
          Heat pumps can deliver up to 4 times more heat energy than the
          electricity they consume. See if they'll work for you!
        </p>

        <Grid container spacing={3}>
          {Object.entries(props.calculators).map(([title, calculator]) => (
            <Grid item xs={12} md={6} key={title}>
              <CalculatorTile
                title={title}
                about={calculator.about}
                link={calculator.link}
                why={calculator.why}
                viewText={calculator.viewText}
                disabled={calculator.disabled}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        sx={{ backgroundColor: sectionBackgroundColor, paddingY: 6 }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2 style={{ fontWeight: "normal", textAlign: "center" }}>
                {" "}
                Frequently asked Questions
              </h2>
              <p>
                Heat pumps are cool technology that have the potential to be
                great for the environment.
              </p>
            </Grid>
            <Grid item xs={12}>
              <FAQ title="What's a Heat Pump?">
                <p>
                  A heat pump is a device that can heat or cool a space using
                  electricity. They can replace a gas burning furnace and
                  dedicated air conditioning unit. They do not burn any fossil
                  fuels onsite, making them environmentally friendly.
                </p>
              </FAQ>
              <FAQ title="How do Heat Pumps work?">
                <p>
                  Heat Pumps work by moving heat from outside to inside. As long
                  as it is above absolute zero outside, there is thermal heat
                  energy. So, even if it is really cold, heat pumps can transfer
                  that thermal energy to inside your home.
                </p>

                <p>
                  This is the same process an air conditioner uses to move
                  thermal energy from inside your home to outside. A heat pump
                  is just air conditioner that can also run in reverse. That
                  means you can also use a heat pump to cool your house.
                </p>

                <p>
                  Heat pumps can be very efficient because they don't create any
                  heat all, they are just moving it from one place to another
                  using electricity. In fact, heat pumps can move more thermal
                  energy than they consume.
                </p>

                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/QykwWs3L1W8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </FAQ>

              <FAQ title="What is COP?">
                <p>
                  COP is a measurement of how effectively a heat pump uses
                  electricity to move heat from place to place. The COP measures
                  the ratio of how much useful heat (or cold) a heat pump will
                  produce if we give it certain energy input.
                </p>

                <p>
                  Typical COPs for heat pumps range from 2 to 4 meaning they
                  deliver 2 to 4 times more heating energy than the electricity
                  they consume
                </p>
              </FAQ>

              <FAQ title="How can the COP be more than 1?">
                <p>
                  Compared to furnaces or a space heater, heat pumps do not
                  create heat. Instead they just move it from one place to
                  another.
                </p>

                <p>
                  As a result, the COP of a heat pump is generally over 1! This
                  means that they move more energy than they consume.
                </p>
              </FAQ>

              <FAQ title="Is there theoretical maximum for COP?">
                <p>Yes, it is calculated with the following formula:</p>

                <p style={{ textAlign: "center" }}>
                  COP<sub>heat pump </sub>= T<sub>hot</sub>/(T<sub>hot</sub>-T
                  <sub>cold</sub>)
                </p>

                <p>
                  T<sub>hot</sub> is the indoor temperature in Kelvin
                </p>

                <p>
                  T<sub>cold</sub> is the temperature in Kelvin outside when the
                  heat pump is running
                </p>

                <p>
                  Let's take a day where we want to heat our home to 70°F
                  (~294K) and the outside temperature is at freezing 32°F
                  (~273K). In this case, the theoretical maximum COP is 294 294
                  / (294 - 273) = 14 COP.
                </p>

                <p>
                  In practice, the COP will be much lower, but this shows what's
                  possible!
                </p>
              </FAQ>

              <FAQ title="Any guides on buying a heat pumps?">
                <p>
                  The Technology Connections channel on Youtube has several
                  videos on heat pumps. This one is a great overview for
                  installing one.
                </p>

                <p>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/43XKfuptnik"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </p>

                <p>
                  The Wire Cutter also has a guide. Read it{" "}
                  <a
                    href="https://www.nytimes.com/wirecutter/guides/heat-pump-buying-guide/"
                    _target="blank"
                  >
                    here
                  </a>
                  .
                </p>
              </FAQ>

              {/* <FAQ title="What is the enviormental impact of heat pumps?">
                TODO TODO TODO TODOTODO TODOTODO TODOTODO TODOTODO TODOTODO
                TODOTODO TODOTODO TODOTODO TODO TODO TODO TODOTODO TODOTODO
                TODOTODO TODOTODO TODOTODO TODOTODO TODOTODO TODOTODO TODOTODO
                TODO TODOTODO TODOTODO TODOTODO TODOTODO TODOTODO TODOTODO
                TODOTODO TODOTODO TODOTODO TODO TODOTODO TODOTODO TODOTODO
                TODOTODO TODOTODO TODOTODO TODOTODO TODOTODO TODO
              </FAQ> */}

              <FAQ title="What if the electricity comes from burning fossil fuels?">
                <p>
                  In many situations, the electricity that powers a heat pump
                  will have been generated by burning a fossil fuel. However,
                  because heat pumps are more energy efficient than burning gas
                  on site, they can still reduce carbon emissions from heating
                  your home.
                </p>
                <p>
                  To break it down a bit more, power plants can convert natural
                  gas into electricity with 40-60% efficiency<sup>1</sup>. That
                  means a heat pump with a COP of at least 2.5 or as low as 1.66
                  can generate more heat energy than burning natural gas on
                  site.
                </p>

                <p>
                  Modern heat pumps can readily achieve those COPs in a wide
                  range of conditions making them an environmentally-friendly
                  choice today even if the power is coming from fossil fuels.
                </p>
                <p>
                  <sup>1</sup>{" "}
                  <a
                    href="http://needtoknow.nas.edu/energy/energy-sources/fossil-fuels/natural-gas/"
                    _target="blank"
                  >
                    The National Academy of Sciences
                  </a>
                </p>
              </FAQ>

              <FAQ title="What happens when it's too cold outside?">
                <p>
                  The latest generation of variable-speed air source heat pumps
                  work well at outdoor temperatures as low as -15°F<sup>1</sup>.
                </p>

                <p>
                  As a back up, you can use a gas burning furnace or electric
                  resistive heating.{" "}
                </p>

                <p>
                  To determine the number of days a year you might need backup
                  heat, checkout the{" "}
                  <a href="/cold-days">Backup Days Calculator</a>.
                </p>
                <p>
                  <sup>1</sup>{" "}
                  <a
                    href="https://www.clasp.ngo/wp-content/uploads/2021/05/3H-Hybrid-Heat-Pumps_CLASP_May26.pdf"
                    _target="blank"
                  >
                    CLASP
                  </a>
                </p>
              </FAQ>

              <FAQ title="If everyone switched to Heat Pumps could the electric grid handle it?">
                <p>
                  If everyone used heat pumps, it would put additional strain on
                  our electric grid. However, one study estimates that the
                  United States could support 97% of our heat load without
                  creating new peak energy loads if we maintained a fossil fuel
                  back up for the coldest days<sup>1</sup>. That would buy us
                  time to do the necessarily energy grid enhances for the final
                  3% of our heating needs
                </p>

                <p>
                  <sup>1</sup>
                  {""}
                  <a
                    href="https://www.sciencedirect.com/science/article/pii/S2542435119305781"
                    _target="blank"
                  >
                    Electricity Load Implications of Space Heating
                    Decarbonization Pathways
                  </a>
                </p>
              </FAQ>

              <FAQ title="What about cooling?">
                <p>
                  While this site mainly focuses on using heat pumps for
                  heating, they can just as well cool a space by running in
                  reverse. When operating in cooling mode, a heat pump is the
                  same as an air conditioner.
                </p>
              </FAQ>

              {/* <FAQ title="Where can I find heat pumps?">
              https://ashp.neep.org/
              </FAQ> */}

              <FAQ title="What if I still have questions?">
                <p>
                  Please go to our <a href="/about">about</a> page and send us a
                  your question so we can answer it here for everyone!
                </p>
              </FAQ>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );
}

function CalculatorTile(props) {
  const popupState = usePopupState({
    variant: "popper",
    popupId: props.link,
  });

  return (
    <Card
      variant="outlined"
      sx={{ p: 2 }}
      // style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.title}
        </Typography>
        <Typography variant="body1">{props.about}</Typography>
      </CardContent>
      <div style={{ flexGrow: 1 }}></div>
      <CardActions>
        <ColorButton
          to={props.link}
          component={Link}
          variant="contained"
          size="small"
          sx={{ mr: 1 }}
          disabled={props.disabled}
        >
          {props.viewText ? props.viewText : "View"}
        </ColorButton>

        {props.why ? (
          <span>
            <Button
              variant="text"
              style={{ color: "#31333f" }}
              size="small"
              {...bindHover(popupState)}
            >
              Why is this useful?
            </Button>
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box style={{ maxWidth: "500px" }}>
                <Typography style={{ margin: 10 }}>{props.why}</Typography>
              </Box>
            </HoverPopover>{" "}
          </span>
        ) : null}
      </CardActions>
    </Card>
  );
}

function FAQ(props) {
  const [answerOpen, setAnswerOpen] = useState(false);
  return (
    <Box
      py={2}
      style={{ cursor: "pointer" }}
      borderBottom={"2px solid"}
      onClick={() => {
        window.gtag("event", "faq.toggle", {
          new_state: answerOpen ? "closed" : "opened",
          faq_title: props.title,
        });
        setAnswerOpen((prev) => !prev);
      }}
    >
      <Typography
        className={"underline-on-hover"}
        sx={{ fontWeight: "normal", fontSize: "1.3rem" }}
      >
        <span>{props.title}</span>{" "}
        {/* {answerOpen ? <i class="arrow down"></i> : <i class="arrow right"></i>} */}
      </Typography>
      <Box display={answerOpen ? "initial" : "none"}>{props.children}</Box>
    </Box>
  );
}

export default Home;
