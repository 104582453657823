import { Route, Routes } from "react-router-dom";
import About from "./About.js";
import "./App.css";
import ColdDays from "./ColdDays";
import COPTarget from "./COPTarget";
import EmissionsSavings from "./EmissionsSavings";
import Home from "./Home.js";
import Page from "./Page.js";
import { Helmet } from "react-helmet";

const calculators = {
  "COP Target": {
    about:
      "Calculate the minimum COP where operating a heat pump is cheaper than a gas furnace",
    why: "Heat Pumps use electricity rather than gas. This means calculating the difference in operating costs depends on local utility prices. This calculator helps you determine the COP where a heat pump is cheaper to operate than a gas furnace.",
    // why:"Use this calculator to determine the minimum COP of a heat pump that will cost you less to operate than a gas furance."
    link: "cop-target",
    component: COPTarget,
  },
  "Backup Days": {
    about:
      "Estimate how often your local temperature drops below a certain threshold.",
    why: "Heat pumps performance depends on the temperature outside and different models are designed for different outside temperatures. When the temperature drops below a certain amount, many systems will rely on a backup heating source. Use this to determine how cold you can expect it to get at your location.",
    link: "cold-days",
    component: ColdDays,
  },
  "Emissions Savings": {
    about:
      "Calculate the CO₂ emissions you could save by switching to a heat pump",
    link: "emission-savings",
    why: "Heat pumps don't emit any CO₂ on site during operation. If they are powered by renewable energy, they are very environmentally friendly.",
    // why: "Gas furnaces burn fuel on site that emit CO₂. Heat pumps emit none. However, the electricity that powers heat pumps might be generated by natural gas. "
    component: EmissionsSavings,
    viewText: "Coming Soon",
    disabled: true,
  },
  "What's Next?": {
    about: "What calculator would you like to see built? Let us know!",
    link: "/about",
    component: About, // hacky way to register the about route
    viewText: "Contact",
  },
};

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>{"Heat Pumps Work"}</title>
        <meta property="og:title" content={"Heat Pumps Work"} />
        <meta
          property="og:description"
          content="Heat pump calculators and information"
        />
      </Helmet>
      <Routes>
        <Route
          path="/"
          element={
            <Page hideTopNav title="Heat Pump Calculators">
              <Home calculators={calculators} />
            </Page>
          }
        />
        {Object.entries(calculators).map(([title, calculator]) => (
          <Route
            key={title}
            path={calculator.link}
            element={
              <Page title={title + " | Heat Pump Work"}>
                <Helmet>
                  <title>{title + " | Heat Pump Work"}</title>
                  <meta
                    property="og:title"
                    content={title + " | Heat Pump Work"}
                  />
                  <meta property="og:description" content={calculator.about} />
                </Helmet>
                <calculator.component about={calculator.about} />
              </Page>
            }
          />
        ))}
      </Routes>
    </div>
  );
}

export default App;

/*


- finish about page
- finish FAQ
- finish whys
- finish copy editing
- default to average american home 
- carbon monoide risk in FAQ
- add tracking
  x turn debug off in production
  - add "why" popover tracking
  - improve page title track. doesn't work on refresh to page
- improve unit drop down



- unit on select
- save state local storage


- Cold Days
  - error state on bad zip code  
  - support celsius 
    - adjus monthly ticks to be monthly

- COP Target
  - give context to COP prediction
  - show COP on a slider
  - add track to cop target change

- emissions calculatr. need to finish

- Styling
  - open graph image


Ideas
- something people can tweet about using a heat pump
- add user accounts with clerk
- get actualy energy usage price https://developer.genability.com/api-reference/tariff-api/
- Why Heat Pumps page
- put zipcode/year in url
- dark mode
- Puns
  - make the Jump to a Pump


CO2 Saved from Heat Pump

- natural gas burned
- co2 emmisions = natural gas burned 

*/
