import { Card, CardContent, Typography } from "@mui/material";

function MetricBox(props) {
  return (
    <Card>
      <CardContent>
        <Typography variant="body2" component="div" align="center">
          {props.title}
        </Typography>
        <Typography variant="h6" component="div" align="center">
          {props.value}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default MetricBox;
