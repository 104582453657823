import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

const TopNav = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const pages = [
    { title: "Home", link: "/", mobileOnly: true },
    { title: "About", link: "/about" },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: "#f9f6f1" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            className="underline--magical-top-nav"
            sx={{
              mr: 2,
              display: {
                xs: "none",
                md: "flex",
              },
              textDecoration: "none",
              color: "#31333f",
            }}
          >
            {/* <img
              src={heatpump}
              alt="Heat Pump Logo"
              style={{
                height: "45px",
                marginBottom: "-12px",
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)",
              }}
            /> */}
            HeatPumpsWork
          </Typography>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" onClick={handleOpenNavMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <a
                      key={page.title}
                      href={page.link}
                      style={{ textDecoration: "none", color: "#31333f" }}
                    >
                      {page.title}
                    </a>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              flexGrow: 0,
              display: { xs: "flex", md: "none" },
              textDecoration: "none",
              color: "#31333f",
              borderBottom: "3px solid rgb(252,198,81)",
            }}
          >
            {/* <img
              src={heatpump}
              alt="Heat Pump Logo"
              style={{
                height: "45px",
                marginBottom: "-12px",
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)",
              }}
            /> */}
            HeatPumpsWork
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => {
              if (page.mobileOnly) {
                return null;
              }
              return (
                <Button
                  key={page.title}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={page.link}
                  sx={{
                    my: 2,
                    display: "block",
                    marginLeft: "auto",
                    color: "#31333f",
                  }}
                >
                  {page.title}
                </Button>
              );
            })}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopNav;
