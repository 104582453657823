import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CalculatorTitle from "./CalculatorTitle";

function getWeatherData(zipcode, start, end, success, error) {
  // console.log(zipcode, start, end);
  // success(JSON.parse(JSON.stringify(mockWeatherData)));
  // return;

  const url =
    "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/" +
    zipcode +
    "/" +
    start +
    "/" +
    end +
    "?unitGroup=us&include=days&key=R3KRLQDRLTXXFKFCMQ233PU8X&contentType=json";

  const data = localStorage.getItem(url);

  if (data) {
    console.log("cache hit!");
    console.log(JSON.parse(data));
    success(JSON.parse(data));
    return;
  }

  fetch(url, {
    method: "GET",
    headers: {},
  })
    .then(function (response) {
      if (!response.ok) {
        throw new Error("HTTP error, status = " + response.status);
      }

      return response.json();
    })
    .then((res) => {
      localStorage.setItem(url, JSON.stringify(res));
      success(res);
    })
    .catch(error);
}

function formatWeatherData(data) {
  return data.days.map((e) => {
    return { date: e.datetime, tempMin: e.tempmin, tempMax: e.tempmax };
  });
}

function ColdDays(props) {
  const [state, setState] = useState({
    zipcode: "60035",
    tempThreshold: 15,
    weatherData: null,
    weatherDataLoading: false,
    year: "2021",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetWeatherData = () => {
    setState((prevState) => ({
      ...prevState,
      weatherData: null,
      weatherDataLoading: false,
    }));
  };

  const handleGetWeatherData = () => {
    window.gtag("event", "get_weather_data", {
      zipcode: state.zipcode,
      year: state.year,
    });

    getWeatherData(
      state.zipcode,
      state.year + "-01-01", //todo change back
      state.year + "-12-31",
      function (response) {
        setState((prevState) => ({
          ...prevState,
          weatherDataLoading: false,
          weatherData: formatWeatherData(response),
        }));
      }
    );

    setState((prevState) => ({
      ...prevState,
      weatherDataLoading: true,
    }));
  };

  const handleChangeCommitted = () => {
    window.gtag("event", "cold_days.tempThreshold", {
      zipcode: state.zipcode,
      year: state.year,
      tempThreshold: state.tempThreshold,
    });
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CalculatorTitle title="Cold days" description={props.about} />
        </Grid>

        <Grid item xs={12}>
          {state.weatherDataLoading || state.weatherData ? (
            <h3>
              Showing data from {state.year} for {state.zipcode}{" "}
              <Button onClick={resetWeatherData}>Reset</Button>
            </h3>
          ) : (
            <WeatherFetchFrom
              handleChange={handleChange}
              zipcode={state.zipcode}
              year={state.year}
              handleGetWeatherData={handleGetWeatherData}
              weatherDataLoading={state.weatherDataLoading}
              weatherData={state.weatherData}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {state.weatherData ? (
            <WeatherAnalysis
              data={state.weatherData}
              tempThreshold={state.tempThreshold}
              handleChange={handleChange}
              handleChangeCommitted={handleChangeCommitted}
              zipcode={state.zipcode}
              year={state.year}
            />
          ) : (
            <AnalysisSkeleton loading={state.weatherDataLoading} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

function AnalysisSkeleton(props) {
  return (
    <Box sx={{ pt: 0.5 }}>
      {props.loading ? (
        <div>
          <Skeleton height={50} animation="wave" />
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={300}
            animation="wave"
          />
        </div>
      ) : null}
    </Box>
  );
}

function WeatherAnalysis(props) {
  const tempThreshold = props.tempThreshold;

  const CustomizedDot = (props) => {
    // eslint-disable-next-line
    const { cx, cy, stroke, payload, value } = props;

    if (value < tempThreshold) {
      return <circle cx={cx} cy={cy} r="2" fill="red" />;
    } else {
      return <circle cx={cx} cy={cy} r="0" />; // returns no dot
    }
  };

  const marks = [
    {
      value: -30,
      label: "-30°F",
    },
    {
      value: -15,
      label: "-15°F",
    },
    {
      value: 0,
      label: "0°F",
    },
    {
      value: 15,
      label: "15°F",
    },
    {
      value: 30,
      label: "30°F",
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent style={{ height: "105px" }}>
            <Typography style={{ fontWeight: "bold" }} align="center">
              Temperature Threshold °F
            </Typography>
            <Box sx={{ width: "100%" }} xs={{ px: 3, m: "auto" }}>
              <Slider
                min={-50}
                max={50}
                label=""
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                marks={marks}
                value={props.tempThreshold}
                name="tempThreshold"
                onChange={props.handleChange}
                onChangeCommitted={props.handleChangeCommitted}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent style={{ height: "105px" }}>
            <Typography
              variant="body2"
              component="div"
              align="center"
              style={{ margin: "12px 0 5px 0" }}
            >
              {"# Days below " + tempThreshold + "°F"}
            </Typography>
            <Typography variant="h5" component="div" align="center">
              {
                props.data.filter((obj) => obj.tempMin < props.tempThreshold)
                  .length
              }
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <div style={{ width: "100%" }}>
          <h3>Historical Daily Minimums in {props.zipcode}</h3>
          <ResponsiveContainer width="100%" minWidth="0" aspect={2}>
            <LineChart
              data={props.data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis angle={-45} textAnchor="end" />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="tempMin"
                name="Daily Minimum Temperature"
                stroke="#8884d8"
                isAnimationActive={false}
                dot={<CustomizedDot />}
                legendType="none"
              />
              <ReferenceLine
                y={props.tempThreshold}
                stroke="red"
                strokeDasharray="3 3"
              >
                <Label
                  value={"Threshold: " + props.tempThreshold + "°F"}
                  position="bottom"
                />
              </ReferenceLine>
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Grid>
      <Grid item xs={12}>
        <h3>Hourly or Multi-year calculations</h3>
        <p>
          Would it be helpful to have this calculator perform hourly or
          multi-year calculations? If so, please{" "}
          <a href="/about">get in touch</a> to let me know.
        </p>
      </Grid>

      <Grid item xs={12}>
        <h3>Calculate COP based on local temperature</h3>
        <p>
          The efficiency of a heat pump varies based on the difference between
          the outside and indoor temperatures. To account for this, heat pumps
          are required to report their Heating Seasonal Performance Factor
          (HSPF) in the United States. However, this efficiency metric is only
          calculated at generic temperatures and doesn't take into account the
          exact climate at your location throughout the heating season.
        </p>

        <p>
          In a future iteration of this calculator, we would like to calculate
          the average COP of different heat pumps at the provided zipcode. The
          missing piece is access to a dataset of the COP vs Temperature
          difference curves. If you know how a dataset we can use, please get in
          touch.
        </p>
      </Grid>
    </Grid>
  );
}

function WeatherFetchFrom(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          type="number"
          label="Zipcode"
          value={props.zipcode}
          sx={{ mr: 1, width: "110px" }}
          name="zipcode"
          onChange={props.handleChange}
        />

        <FormControl>
          <InputLabel id="year-label">Year</InputLabel>
          <Select
            id="year"
            labelId="year-label"
            value={props.year}
            label="year"
            name="year"
            sx={{ mr: 1 }}
            onChange={props.handleChange}
          >
            <MenuItem value={"2021"}>2021</MenuItem>
            <MenuItem value={"2020"}>2020</MenuItem>
            <MenuItem value={"2019"}>2019</MenuItem>
            <MenuItem value={"2019"}>2018</MenuItem>
            <MenuItem value={"2019"}>2017</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <LoadingButton
          onClick={props.handleGetWeatherData}
          loading={props.weatherDataLoading}
          variant="contained"
          sx={{}}
          disabled={
            props.zipcode === "" ||
            props.zipcode.length !== 5 ||
            props.weatherData != null
          }
        >
          Fetch Data
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default ColdDays;
