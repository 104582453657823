import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import CalculatorTitle from "./CalculatorTitle";
import MetricBox from "./MetricBox";

function EmissionsSavings(props) {
  const [state, setState] = useState({
    gasUsage: 317.2,
    gasUsageUnit: "therm",
    cop: 3,
    electricity_source: "grid",
    electricity_source_emissions_per_pound: 0.85,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const THERM_TO_MMBtu = 0.0999761;

  const MCF_TO_MMBtu = 1.037;

  const pounds_co2_per_MMBtu = 117;

  let gasUsageMMBtu = state.gasUsage * THERM_TO_MMBtu;
  if (state.gasUsageUnit === "mcf") {
    gasUsageMMBtu = state.gasUsage * MCF_TO_MMBtu;
  }

  const co2_emissions = gasUsageMMBtu * pounds_co2_per_MMBtu;

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CalculatorTitle
            title="Emissions Savings"
            description={props.about}
          />
        </Grid>

        <Grid item xs={8}>
          <h3>Natural Gas Usage</h3>
          <p>Estimate the monthly natural gas usage in your household</p>
          <TextField
            type="number"
            sx={{ mr: 1 }}
            label="Monthly Gas Usage"
            value={state.gasUsage}
            variant="outlined"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              step: 1,
            }}
            onChange={handleChange}
            name="gasUsage"
          />

          <FormControl>
            <InputLabel id="gas-unit-label">Year</InputLabel>

            <Select
              id="gas-unit"
              labelId="gas-unit-label"
              value={state.gasUsageUnit}
              label="Unit"
              name="gasUsageUnit"
              onChange={handleChange}
            >
              <MenuItem value={"therm"}>Therm</MenuItem>
              <MenuItem value={"mcf"}>Thousand Cubic Feet</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ mt: 7 }}></Box>
          <MetricBox
            title="Emissions from furnace¹"
            value={co2_emissions.toFixed(0) + " pounds of CO₂"}
          />
        </Grid>

        <Grid item xs={8}>
          <h3>Heat Pump COP</h3>
          <p sx={{ mt: 4 }}>
            Estimate the average COP of your heat pump during operation. 2 - 5
            is a good range.
          </p>
          <TextField
            type="number"
            sx={{ mr: 1 }}
            label="Heat Pump COP"
            value={state.cop}
            variant="outlined"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              step: 1,
            }}
            onChange={handleChange}
            name="cop"
          />
        </Grid>

        <Grid item xs={8}>
          <h3>Electricity Source</h3>
          <p>
            Heat pumps don't emit any CO₂ during their operation. The only CO₂
            emissions from operation come from your source of electricity.
          </p>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="electricity_source"
              value={state.electricity_source}
              onChange={handleChange}
            >
              <FormControlLabel
                label="100% Renewable Energy"
                control={<Radio />}
                value="renewable"
              />
              <FormControlLabel
                label="Electric Grid"
                control={<Radio />}
                value="grid"
              />
            </RadioGroup>
          </FormControl>

          {state.electricity_source === "renewable" ? null : (
            <div>
              <TextField
                type="number"
                sx={{ mr: 1 }}
                label="Pounds of CO₂ emissions / kWh"
                value={state.electricity_source_emissions_per_pound}
                variant="outlined"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  step: 0.1,
                }}
                onChange={handleChange}
                name="electricity_source_emissions_per_pound"
              />
            </div>
          )}
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ mt: 7 }}></Box>
          <MetricBox
            title="Emissions from electric consumption"
            value={co2_emissions.toFixed(0) + " pounds of CO₂"}
          />
        </Grid>

        <Grid item xs={12}>
          <p>
            <sup>1</sup> About 117 pounds of CO2 are produced per million
            British thermal units (MMBtu) equivalent of natural gas. (
            <a
              href="https://www.eia.gov/energyexplained/natural-gas/natural-gas-and-the-environment.php#:~:text=About%20117%20pounds%20of%20CO2%20are%20produced%20per%20million%20British%20thermal%20units%20(MMBtu)%20equivalent%20of%20natural%20gas"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Source
            </a>
            )
          </p>

          <p>
            <sup>2</sup> In 2020, total U.S. electricity generation by the
            electric power industry of 4.01 trillion kilowatthours (kWh) from
            all energy sources resulted in the emission of 1.55 billion metric
            tons. This equaled about 0.85 pounds of CO2 emissions per kWh. (
            <a
              href="https://www.eia.gov/tools/faqs/faq.php?id=74&t=11#:~:text=In%202020%2C%20total%20U.S.%20electricity,CO2%20emissions%20per%20kWh."
              target={"_blank"}
              rel={"noreferrer"}
            >
              Source
            </a>
            )
          </p>
        </Grid>
      </Grid>

      {/* qualfier about refirdgerant */}
    </Container>
  );
}

export default EmissionsSavings;
