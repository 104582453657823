import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import TopNav from "./TopNav.js";

const Page = (props) => {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  return (
    <Box>
      {props.hideTopNav ? null : <TopNav />}
      {props.children}
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: "#523A34",
          py: 3,
          color: "#fcfaf7",
        }}
      >
        <Container maxWidth="sm">
          {/* <Divider color="#b9b2a9" /> */}
          <Box sx={{ mt: 4, mb: 5 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                // color="text.secondary"
                component="p"
                align="center"
                style={{ display: "block" }}
              >
                This site is currently under active development. Feedback
                welcome!
              </Typography>

              <Typography
                variant="body2"
                component="p"
                align="center"
                color="#b9b2a9"
                sx={{ m: 1 }}
              >
                <a href="/about" style={{ color: "#b9b2a9" }}>
                  About
                </a>
                {" • "}
                {/* Built by{" "}
                <a
                  href="https://www.jmaxkanter.com/"
                  style={{ color: "#b9b2a9" }}
                >
                  @maxk
                </a>{" "} */}
                Version 0.2
              </Typography>
            </Grid>
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

export default Page;
